// Webpack Imports
import * as bootstrap from 'bootstrap';
import 'slick-carousel';
import lightbox from 'lightbox2';

(function () {
    'use strict';
})();

jQuery(document).ready(function ($) {
    $(window).on('load resize', function (e) {
        var element = $('.gradient');
        var offset = $(window).width() - (element.offset().left + element.innerWidth());
        var widthNew = element.width() + offset + 24 + 'px';
        var zmiennaCss = '--width';
        var nowaWartosc = widthNew;
        element.css(zmiennaCss, nowaWartosc);
    });

    $('.opinion-slider').slick({
        mobileFirst: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        prevArrow: '<button type="button" class="slick-prev"><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.9496 8.45752L13.8308 10.3475L9.47035 14.6876L27.6105 14.7057L27.6078 17.3724L9.51742 17.3542L13.8044 21.6612L11.9142 23.5424L4.38942 15.9824L11.9496 8.45752Z" fill="#7a7a7a"/></svg></button>',
        nextArrow: '<button type="button" class="slick-next"><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.0504 8.45752L18.1692 10.3475L22.5296 14.6876L4.38947 14.7057L4.39216 17.3724L22.4825 17.3542L18.1956 21.6612L20.0857 23.5424L27.6105 15.9824L20.0504 8.45752Z" fill="#7a7a7a"/></svg></button>',
        arrows: false,
        dots: true,
        swipeToSlide: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    adaptiveHeight: true,
                },
            },
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 3,
                    arrows: true,
                    adaptiveHeight: true,
                },
            },
        ],
    });
    lightbox.option({
        resizeDuration: 200,
        wrapAround: true,
        albumLabel: 'Zdjęcie %1 z %2',
    });

    // Navbar
    function navbar() {
        if (($(window).scrollTop() > 50 && !$('.navbar').hasClass('navbar--scroll')) || ($(window).scrollTop() === 0 && $('.navbar').hasClass('navbar--scroll'))) {
            $('.navbar').toggleClass('navbar--scroll');
        }
    }
    window.addEventListener(
        'scroll',
        function () {
            navbar();
        },
        {passive: true}
    );

    $(document).ready(function () {
        navbar();
    });

    $('.dropdown-menu > li > .dropdown-menu').parent().addClass('dropdown-submenu').find(' > .dropdown-item').attr('href', 'javascript:;').addClass('dropdown-toggle');
    $('.dropdown-submenu > a').on('click', function (e) {
        var dropdown = $(this).parent().find(' > .show');
        $('.dropdown-submenu .dropdown-menu').not(dropdown).removeClass('show');
        $(this).next('.dropdown-menu').toggleClass('show');
        e.stopPropagation();
    });
    $('.dropdown').on('hidden.bs.dropdown', function () {
        $('.dropdown-menu.show').removeClass('show');
    });
});
